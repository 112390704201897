import { Injectable } from '@angular/core';
import { AttendeeRcsService, WebinarAttendeeService } from './services';
import { AppService, RestService } from '../core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';

interface mediaStateInst {
    participantId: string;
    audio: boolean;
    video: boolean;
    participantType: 'screenshare' | 'cloudvideo' | 'user';
    isDisplayName: boolean;
    inGrid: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AttendeeViewStateService {
    layoutState = {
        galleryParticipantCount: 0, // Set whenever there is a new tile in gallery view (Init of participant view)
        mediaShare: null, // 'cloudvideo' | 'screenshare' (Called from gallery view - either add or remove screenshare | cloud video)
        spotlight: false, // Called from gallery view (Set when there are spotlight participants)
        gridType: 'normal' // 'normal' | 'equaltile' (Called from gallery view - ngOnInit)
    };
    participants: any[] = []; // Modified based on ngOnInit and Destroy of participant view
    lastUpdatedState: any = null;
    audioState = [];
    videoState = [];
    meetingId: any;
    lastPublishedViewState: any;
    isRecorderBot: boolean = false;
    updatesInterval;
    private videoFeedSharedSubject = new BehaviorSubject<boolean>(false);
    private spotLightedSubject = new BehaviorSubject<boolean>(false);
    isVideoFeedShared$: Observable<boolean> = this.videoFeedSharedSubject.asObservable();
    isSpotLighted$: Observable<boolean> = this.spotLightedSubject.asObservable();

    constructor(
        private webinarAttendeeService: WebinarAttendeeService,
        private appService: AppService,
        private restService: RestService,
        private roomConnectionService: AttendeeRcsService
    ) {}

    setRecorderBotState(value) {
        this.isRecorderBot = value;
        if (value) {
            this.updatesInterval = setInterval(() => {
                this.updateViewState();
            }, 5000);
        }
    }

    stopInterval() {
        clearInterval(this.updatesInterval);
    }

    /**
     * This function sends a media engine event status.
     * Note: This endpoint is NOT consumed by the API server.
     * It is only used to log events at the nginx level.
     */
    sendMediaEngineEventStatus(activityState) {
        fetch(`/recordingstatus/${this.meetingId}/success/?activity=${activityState}`, {})
            .then((res) => {
                console.log('posted recording activity status');
            })
            .catch((err) => {
                console.log(err);
            });
    }

    /**
     *
     * @param payload
     * @returns Observable<any>
     * This function to be called once in atleast configured time ()
     */
    sendRecorderViewStateUpdate(payload) {
        if (!this.isRecorderBot) return of(null);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${this.meetingId}/recorder-bot/status/guest`,
            payload,
            { headers: this.webinarAttendeeService.headers }
        );
    }

    updateViewState = _.throttle(
        async () => {
            const payload = {
                layoutState: this.layoutState,
                mediaState: [
                    {
                        type: 'audio',
                        state: this.audioState
                    },
                    {
                        type: 'video',
                        state: this.videoState
                    }
                ],
                misc: {
                    participants: this.participants
                }
            };
            try {
                console.log('Attendee view state update', payload);
                await this.sendRecorderViewStateUpdate(payload).toPromise();
                this.lastPublishedViewState = payload;
            } catch (e) {
                console.log('Error in updating view state', e);
            }
        },
        1000,
        { trailing: true }
    );

    setMediaShareState(type: 'cloudvideo' | 'screenshare' = null) {
        /**
         * When media share update happens,
         * Cloudvideo has only one tile, and played in participant view
         * whereas screenshare is handled in gallery view itself
         *
         */
        if (this.layoutState.mediaShare !== type) {
            console.log('Setting media share state to', type);
            this.layoutState.mediaShare = type;
            this.updateViewState();
        }
    }

    setSpotlightState(state: boolean) {
        /**
         * When spotlight update happens,
         * Existing gallery is refreshed with list of spotlighted participants
         *
         */
        if (this.layoutState.spotlight !== state) {
            console.log('Setting spotlight state to', state);
            this.spotLightedSubject.next(state);
            this.layoutState.spotlight = state;
            this.updateViewState();
        }
    }

    setGridType(state: string = 'normal') {
        if (this.layoutState.gridType !== state) {
            console.log('Setting grid type to', state);
            this.layoutState.gridType = state;
            this.updateViewState();
        }
    }

    addParticipantInGrid(participant: any, isScreenshare: boolean = false) {
        const index = this.participants.findIndex((p) => p.participantId === participant.uid);
        if (index !== -1) return;
        this.participants.push({
            participantId: participant.uid,
            type: this.getParticipantType(participant, isScreenshare)
        });
        this.layoutState.galleryParticipantCount = this.participants.length;
        this.updateViewState();
    }

    removeFromGrid(participant: any) {
        const index = this.participants.findIndex((p) => p.participantId === participant.uid);
        if (index == -1) return;
        this.participants.splice(index, 1);
        this.layoutState.galleryParticipantCount = this.participants.length;
        this.updateViewState();
    }

    updateMediaState(participant: any, mediaType: 'audio' | 'video', state: boolean, isScreenshare: boolean = false) {
        /**
         * Audio and video states are to be updated here.
         * This function is called when participant tile is created or destroyed.
         * When audio is subscribed or unsubscribed from jm media service.
         */
        try {
            if (mediaType == 'audio') {
                this.handleAudioState(participant, state, isScreenshare);
            }
            if (mediaType == 'video') {
                this.handleVideoState(participant, state, isScreenshare);
            }
            this.updateViewState();
        } catch (err) {
            console.log('Error in updating media state', err);
        }
    }

    handleAudioState(participant: any, state: boolean, isScreenshare: boolean = false) {
        const index = this.getParticipantIndex(participant, 'audio', isScreenshare);
        if (state && index == -1) {
            this.audioState.push(this.getParticipantObjectForStates(participant, isScreenshare));
        } else if (!state && index !== -1) {
            this.audioState.splice(index, 1);
        }
        console.log('Attendee Status: Audio states: ', this.audioState);
    }

    handleVideoState(participant: any, state: boolean, isScreenshare: boolean = false) {
        const index = this.getParticipantIndex(participant, 'video', isScreenshare);
        if (state && index == -1) {
            this.videoState.push(this.getParticipantObjectForStates(participant, isScreenshare));
            const isVideoFeedShared = this.videoState.length === 0 ? false : true;
            this.videoFeedSharedSubject.next(isVideoFeedShared);
        } else if (!state && index !== -1) {
            this.videoState.splice(index, 1);
            const isVideoFeedShared = this.videoState.length === 0 ? false : true;
            this.videoFeedSharedSubject.next(isVideoFeedShared);
        }
        console.log('Attendee Status: Video states: ', this.videoState);
    }

    getParticipantType(participant: any, isScreenshare: boolean) {
        return participant.role == 'CPBot' ? 'cloud video' : isScreenshare ? 'screen share' : 'user';
    }

    getParticipantIndex(participant: any, mediaType: string, isScreenshare: boolean): number {
        return (mediaType == 'video' ? this.videoState : this.audioState).findIndex(
            (p) => p.participantId === participant.uid && p.type === this.getParticipantType(participant, isScreenshare)
        );
    }

    getParticipantName(participant, participantType) {
        return participantType == 'cloud video'
            ? 'Cloud Video'
            : participantType == 'screen share'
            ? 'Screen Share'
            : this.roomConnectionService.getParticipantName(participant.uid);
    }

    getParticipantObjectForStates(participant, isScreenshare) {
        let participantType = this.getParticipantType(participant, isScreenshare);
        return {
            participantId: participant.uid,
            type: participantType,
            name: this.getParticipantName(participant, participantType)
        };
    }
}
