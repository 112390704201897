<div class="slider-container">
    <img
        [src]="
            volume == 0
                ? 'assets/img/jioevents/dark_theme/no_volume_icon.svg'
                : 'assets/img/jioevents/dark_theme/full_volume_icon.svg'
        "
        alt="Volume Icon"
        width="24"
        height="24"
        class="volume-icon"
        (click)="toggleVolume()"
    />
    <input
        type="range"
        min="0"
        max="100"
        class="slider"
        id="volume"
        [(ngModel)]="volume"
        (input)="onVolumeChange($event)"
    />
</div>
