import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-volume-controller',
    templateUrl: './volume-controller.component.html',
    styleUrls: ['./volume-controller.component.scss']
})
export class VolumeControllerComponent implements OnInit {
    volume: any;
    @Output() volumeToggled = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit(): void {}

    toggleVolume() {
        this.volumeToggled.emit(false);
    }

    onVolumeChange(event: any) {
        this.volume = event.target;
        console.log(this.volume);
    }
}
