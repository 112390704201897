<div class="layout-controls-wrapper-component">
    <div
        class="layout-icon"
        [class.active]="showLayoutPopUp"
        (click)="togglePopup()"
        pTooltip="Tile Layouts"
        tooltipPosition="top"
        tooltipStyleClass="tooltip-revamp"
        id="layoutToggleBtn"
    ></div>
    <div class="layout-options-wrapper" *ngIf="showLayoutPopUp">
        <div class="layout-container-header">
            <div class="layout-title">
                <div class="title">Attendee View</div>
                <div>
                    <img
                        id="toggleCloseBtn"
                        class="pointer ml-auto"
                        src="assets/images/livestream/close_white_icon.svg"
                        (click)="discardFormPopUp()"
                    />
                </div>
            </div>
            <div class="description">Select the suitable speaker tile layout for your attendees</div>
        </div>
        <div class="layout-container-body">
            <div class="layout-options-container">
                <!-- Grid Layout Option -->
                <div class="layout-option" (click)="selectLayout('grid')">
                    <img
                        [src]="
                            selectedLayout === 'grid'
                                ? 'assets/images/speaker-call-controls/grid-layout-active.svg'
                                : 'assets/images/speaker-call-controls/grid-layout.svg'
                        "
                        alt="Grid Layout"
                        class="layout-img"
                    />
                    <div class="layout-title" [class.highlight-text]="selectedLayout === 'grid'">Grid Layout</div>
                </div>

                <!-- Active Speaker Layout Option -->
                <div class="layout-option" (click)="selectLayout('speaker')">
                    <img
                        [src]="
                            selectedLayout === 'speaker'
                                ? 'assets/images/speaker-call-controls/speaker-layout-active.svg'
                                : 'assets/images/speaker-call-controls/speaker-layout.svg'
                        "
                        alt="Active Speaker Layout"
                        class="layout-img"
                    />
                    <div class="layout-title" [class.highlight-text]="selectedLayout === 'speaker'">Active Speaker</div>
                </div>
            </div>
        </div>
        <div class="layout-container-footer">
            <div class="button-container">
                <button class="confirm-btn bg-login" (click)="confirmLayoutSelection()" [disabled]="!selectionChanged">
                    Apply Layout
                </button>
            </div>
        </div>
    </div>
</div>
