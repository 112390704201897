<div
    class="studio-feed-wrapper"
    [id]="wrapperId"
    [ngClass]="{
        'attendee-view': source === 'attendee',
        mobile: isMobileScreen,
        'panel-open': callViewStateManagerService?.calloptionsPanelOpenState,
        isPaused: eventsPlusService.isPaused && source === 'attendee'
    }"
>
    <div class="elements-container no-select" [id]="feedContainerId" [class.PipMode]="isPipMode || isMobileScreen">
        <!-- <canvas
            id="playground-canvas"
            [class.hide]="eventsPlusService.isPaused || eventsPlusService.isStopped"
            [class.isPortrait]="isPortrait"
        >
        </canvas> -->
    </div>
</div>
