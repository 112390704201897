export const APP_CONSTANT = {
    CHAT_MESSAGE_LIMIT: 1300,
    WEBINAR_POLLING_EVENT_STATUS_INTERVAL: 5000, // in milisecond
    WEBINAR_ATTENDEE_SOCKET_STATE: 'attendee_event_websocket',
    WEBINAR_HOST_SOCKET_STATE: 'host_event_websocket',
    WEBINAR_ATTENDEE_POLLING_STATE: 'attendee_event_polling',
    WEBINAR_HOST_POLLING_STATE: 'host_event_polling',
    WEBINAR_ATTENDEE_ADD_TO_CAL: 'attendee_add_to_cal',
    WEBINAR_ATTENDEE_ADD_TO_CAL_MOB: 'attendee_add_to_cal_mob',
    WEBINAR_HOST_SHARE_EVENT_SOCIAL: 'host_social_share_event',
    WEBINAR_REG_ENHANCEMENT: 'webinar_reg_enhancement',
    JOB_STATUS_INTERVAL: 3000,
    DOWNLOAD_FILE_INTERVAL: 2000,
    KEY_IS_FROM_RELOAD: 'isFromReload',
    MAX_VISIBLE_SPEAKERS_WEB_VIEW: 8,
    MAX_VISIBLE_SPEAKERS_MOBILE_VIEW: 4,
    DEFAULT_SESSION_JOIN_ENABLE: 5, // time in min to enable join button before the session start time.
    WEBINAR_MAX_COHOST: 4,
    WEBINAR_MAX_SPEAKER: 45,
    SESSION_ALERT: {
        // IN MIN
        REMAINING_DURATION_15: 15,
        REMAINING_DURATION_5: 5,
        REMAINING_DURATION_1: 1
    },
    HOST_ENDED_WEBINAR: 'host has ended webinar',
    MAX_POST_LENGTH: 256,
    CONTEST_TAB_ID: 7,
    ADDONS_AUTH_TOKEN: 'addons_token',
    MEDIA_MAX_FILE_SIZE: {
        IMAGE: 10,
        VIDEO: 100
    },
    SOCIAL_FEED_POST_LIMIT: 20,
    REGISTRATION_FIELDS: [
        {
            name: 'Full Name',
            order: 0,
            required: true,
            custom: false,
            key: 'fullName'
        },
        {
            name: 'Email ID/Mobile No',
            order: 2,
            required: true,
            custom: false,
            key: 'identity'
        }
    ],
    KEYCODES: {
        ZERO: 48,
        NINE: 57
    },
    PUBLISH_TYPE: {
        PUBLIC: 'Public',
        ORGANISATION: 'Limited',
        PRIVATE: 'Private'
    },
    EVENT_CARDS_PER_VIEW: 15,
    COUNTRY_CODE: [
        {
            flagEmoji: '🇮🇳',
            value: '+91',
            name: 'India'
        },
        {
            flagEmoji: '🇦🇫',
            value: '+93',
            name: 'Afghanistan'
        },
        {
            flagEmoji: '🇦🇽',
            value: '+358',
            name: 'Aland Islands'
        },
        {
            flagEmoji: '🇦🇱',
            value: '+355',
            name: 'Albania'
        },
        {
            flagEmoji: '🇩🇿',
            value: '+213',
            name: 'Algeria'
        },
        {
            flagEmoji: '🇦🇸',
            value: '+1684',
            name: 'AmericanSamoa'
        },
        {
            flagEmoji: '🇦🇩',
            value: '+376',
            name: 'Andorra'
        },
        {
            flagEmoji: '🇦🇴',
            value: '+244',
            name: 'Angola'
        },
        {
            flagEmoji: '🇦🇮',
            value: '+1264',
            name: 'Anguilla'
        },
        {
            flagEmoji: '🇦🇶',
            value: '+672',
            name: 'Antarctica'
        },
        {
            flagEmoji: '🇦🇬',
            value: '+1268',
            name: 'Antigua and Barbuda'
        },
        {
            flagEmoji: '🇦🇷',
            value: '+54',
            name: 'Argentina'
        },
        {
            flagEmoji: '🇦🇲',
            value: '+374',
            name: 'Armenia'
        },
        {
            flagEmoji: '🇦🇼',
            value: '+297',
            name: 'Aruba'
        },
        {
            flagEmoji: '🇦🇺',
            value: '+61',
            name: 'Australia'
        },
        {
            flagEmoji: '🇦🇹',
            value: '+43',
            name: 'Austria'
        },
        {
            flagEmoji: '🇦🇿',
            value: '+994',
            name: 'Azerbaijan'
        },
        {
            flagEmoji: '🇧🇸',
            value: '+1242',
            name: 'Bahamas'
        },
        {
            flagEmoji: '🇧🇭',
            value: '+973',
            name: 'Bahrain'
        },
        {
            flagEmoji: '🇧🇩',
            value: '+880',
            name: 'Bangladesh'
        },
        {
            flagEmoji: '🇧🇧',
            value: '+1246',
            name: 'Barbados'
        },
        {
            flagEmoji: '🇧🇾',
            value: '+375',
            name: 'Belarus'
        },
        {
            flagEmoji: '🇧🇪',
            value: '+32',
            name: 'Belgium'
        },
        {
            flagEmoji: '🇧🇿',
            value: '+501',
            name: 'Belize'
        },
        {
            flagEmoji: '🇧🇯',
            value: '+229',
            name: 'Benin'
        },
        {
            flagEmoji: '🇧🇲',
            value: '+1441',
            name: 'Bermuda'
        },
        {
            flagEmoji: '🇧🇹',
            value: '+975',
            name: 'Bhutan'
        },
        {
            flagEmoji: '🇧🇴',
            value: '+591',
            name: 'Bolivia, Plurinational State of'
        },
        {
            flagEmoji: '🇧🇦',
            value: '+387',
            name: 'Bosnia and Herzegovina'
        },
        {
            flagEmoji: '🇧🇼',
            value: '+267',
            name: 'Botswana'
        },
        {
            flagEmoji: '🇧🇷',
            value: '+55',
            name: 'Brazil'
        },
        {
            flagEmoji: '🇮🇴',
            value: '+246',
            name: 'British Indian Ocean Territory'
        },
        {
            flagEmoji: '🇧🇳',
            value: '+673',
            name: 'Brunei Darussalam'
        },
        {
            flagEmoji: '🇧🇬',
            value: '+359',
            name: 'Bulgaria'
        },
        {
            flagEmoji: '🇧🇫',
            value: '+226',
            name: 'Burkina Faso'
        },
        {
            flagEmoji: '🇧🇮',
            value: '+257',
            name: 'Burundi'
        },
        {
            flagEmoji: '🇰🇭',
            value: '+855',
            name: 'Cambodia'
        },
        {
            flagEmoji: '🇨🇲',
            value: '+237',
            name: 'Cameroon'
        },
        {
            flagEmoji: '🇨🇦',
            value: '+1',
            name: 'Canada'
        },
        {
            flagEmoji: '🇨🇻',
            value: '+238',
            name: 'Cape Verde'
        },
        {
            flagEmoji: '🇰🇾',
            value: '+ 345',
            name: 'Cayman Islands'
        },
        {
            flagEmoji: '🇨🇫',
            value: '+236',
            name: 'Central African Republic'
        },
        {
            flagEmoji: '🇹🇩',
            value: '+235',
            name: 'Chad'
        },
        {
            flagEmoji: '🇨🇱',
            value: '+56',
            name: 'Chile'
        },
        {
            flagEmoji: '🇨🇳',
            value: '+86',
            name: 'China'
        },
        {
            flagEmoji: '🇨🇽',
            value: '+61',
            name: 'Christmas Island'
        },
        {
            flagEmoji: '🇨🇨',
            value: '+61',
            name: 'Cocos (Keeling) Islands'
        },
        {
            flagEmoji: '🇨🇴',
            value: '+57',
            name: 'Colombia'
        },
        {
            flagEmoji: '🇰🇲',
            value: '+269',
            name: 'Comoros'
        },
        {
            flagEmoji: '🇨🇬',
            value: '+242',
            name: 'Congo'
        },
        {
            flagEmoji: '🇨🇩',
            value: '+243',
            name: 'Congo, The Democratic Republic of the Congo'
        },
        {
            flagEmoji: '🇨🇰',
            value: '+682',
            name: 'Cook Islands'
        },
        {
            flagEmoji: '🇨🇷',
            value: '+506',
            name: 'Costa Rica'
        },
        {
            flagEmoji: '🇨🇮',
            value: '+225',
            name: "Cote d'Ivoire"
        },
        {
            flagEmoji: '🇭🇷',
            value: '+385',
            name: 'Croatia'
        },
        {
            flagEmoji: '🇨🇺',
            value: '+53',
            name: 'Cuba'
        },
        {
            flagEmoji: '🇨🇾',
            value: '+357',
            name: 'Cyprus'
        },
        {
            flagEmoji: '🇩🇴',
            value: '+420',
            name: 'Czech Republic'
        },
        {
            flagEmoji: '🇩🇰',
            value: '+45',
            name: 'Denmark'
        },
        {
            flagEmoji: '🇩🇯',
            value: '+253',
            name: 'Djibouti'
        },
        {
            flagEmoji: '🇩🇲',
            value: '+1767',
            name: 'Dominica'
        },
        {
            flagEmoji: '🇩🇴',
            value: '+1849',
            name: 'Dominican Republic'
        },
        {
            flagEmoji: '🇪🇨',
            value: '+593',
            name: 'Ecuador'
        },
        {
            flagEmoji: '🇪🇬',
            value: '+20',
            name: 'Egypt'
        },
        {
            flagEmoji: '🇸🇻',
            value: '+503',
            name: 'El Salvador'
        },
        {
            flagEmoji: '🇬🇶',
            value: '+240',
            name: 'Equatorial Guinea'
        },
        {
            flagEmoji: '🇪🇷',
            value: '+291',
            name: 'Eritrea'
        },
        {
            flagEmoji: '🇪🇪',
            value: '+372',
            name: 'Estonia'
        },
        {
            flagEmoji: '🇪🇹',
            value: '+251',
            name: 'Ethiopia'
        },
        {
            flagEmoji: '🇫🇰',
            value: '+500',
            name: 'Falkland Islands (Malvinas)'
        },
        {
            flagEmoji: '🇫🇴',
            value: '+298',
            name: 'Faroe Islands'
        },
        {
            flagEmoji: '🇫🇯',
            value: '+679',
            name: 'Fiji'
        },
        {
            flagEmoji: '🇫🇮',
            value: '+358',
            name: 'Finland'
        },
        {
            flagEmoji: '🇫🇷',
            value: '+33',
            name: 'France'
        },
        {
            flagEmoji: '🇬🇫',
            value: '+594',
            name: 'French Guiana'
        },
        {
            flagEmoji: '🇵🇫',
            value: '+689',
            name: 'French Polynesia'
        },
        {
            flagEmoji: '🇬🇦',
            value: '+241',
            name: 'Gabon'
        },
        {
            flagEmoji: '🇬🇲',
            value: '+220',
            name: 'Gambia'
        },
        {
            flagEmoji: '🇬🇪',
            value: '+995',
            name: 'Georgia'
        },
        {
            flagEmoji: '🇩🇪',
            value: '+49',
            name: 'Germany'
        },
        {
            flagEmoji: '🇬🇭',
            value: '+233',
            name: 'Ghana'
        },
        {
            flagEmoji: '🇬🇮',
            value: '+350',
            name: 'Gibraltar'
        },
        {
            flagEmoji: '🇬🇷',
            value: '+30',
            name: 'Greece'
        },
        {
            flagEmoji: '🇬🇱',
            value: '+299',
            name: 'Greenland'
        },
        {
            flagEmoji: '🇬🇩',
            value: '+1473',
            name: 'Grenada'
        },
        {
            flagEmoji: '🇬🇵',
            value: '+590',
            name: 'Guadeloupe'
        },
        {
            flagEmoji: '🇬🇺',
            value: '+1671',
            name: 'Guam'
        },
        {
            flagEmoji: '🇬🇹',
            value: '+502',
            name: 'Guatemala'
        },
        {
            flagEmoji: '🇬🇬',
            value: '+44',
            name: 'Guernsey'
        },
        {
            flagEmoji: '🇬🇳',
            value: '+224',
            name: 'Guinea'
        },
        {
            flagEmoji: '🇬🇼',
            value: '+245',
            name: 'Guinea-Bissau'
        },
        {
            flagEmoji: '🇬🇾',
            value: '+595',
            name: 'Guyana'
        },
        {
            flagEmoji: '🇭🇹',
            value: '+509',
            name: 'Haiti'
        },
        {
            flagEmoji: '🇻🇦',
            value: '+379',
            name: 'Holy See (Vatican City State)'
        },
        {
            flagEmoji: '🇭🇳',
            value: '+504',
            name: 'Honduras'
        },
        {
            flagEmoji: '🇭🇰',
            value: '+852',
            name: 'Hong Kong'
        },
        {
            flagEmoji: '🇭🇺',
            value: '+36',
            name: 'Hungary'
        },
        {
            flagEmoji: '🇮🇸',
            value: '+354',
            name: 'Iceland'
        },
        {
            flagEmoji: '🇮🇩',
            value: '+62',
            name: 'Indonesia'
        },
        {
            flagEmoji: '🇮🇷',
            value: '+98',
            name: 'Iran, Islamic Republic of Persian Gulf'
        },
        {
            flagEmoji: '🇮🇶',
            value: '+964',
            name: 'Iraq'
        },
        {
            flagEmoji: '🇮🇪',
            value: '+353',
            name: 'Ireland'
        },
        {
            flagEmoji: '🇮🇲',
            value: '+44',
            name: 'Isle of Man'
        },
        {
            flagEmoji: '🇮🇱',
            value: '+972',
            name: 'Israel'
        },
        {
            flagEmoji: '🇮🇹',
            value: '+39',
            name: 'Italy'
        },
        {
            flagEmoji: '🇯🇲',
            value: '+1876',
            name: 'Jamaica'
        },
        {
            flagEmoji: '🇯🇵',
            value: '+81',
            name: 'Japan'
        },
        {
            flagEmoji: '🇯🇪',
            value: '+44',
            name: 'Jersey'
        },
        {
            flagEmoji: '🇯🇴',
            value: '+962',
            name: 'Jordan'
        },
        {
            flagEmoji: '🇰🇿',
            value: '+77',
            name: 'Kazakhstan'
        },
        {
            flagEmoji: '🇰🇪',
            value: '+254',
            name: 'Kenya'
        },
        {
            flagEmoji: '🇰🇮',
            value: '+686',
            name: 'Kiribati'
        },
        {
            flagEmoji: '🇰🇵',
            value: '+850',
            name: "Korea, Democratic People's Republic of Korea"
        },
        {
            flagEmoji: '🇰🇷',
            value: '+82',
            name: 'Korea, Republic of South Korea'
        },
        {
            flagEmoji: '🇰🇼',
            value: '+965',
            name: 'Kuwait'
        },
        {
            flagEmoji: '🇰🇬',
            value: '+996',
            name: 'Kyrgyzstan'
        },
        {
            flagEmoji: '🇱🇦',
            value: '+856',
            name: 'Laos'
        },
        {
            flagEmoji: '🇱🇻',
            value: '+371',
            name: 'Latvia'
        },
        {
            flagEmoji: '🇱🇧',
            value: '+961',
            name: 'Lebanon'
        },
        {
            flagEmoji: '🇱🇸',
            value: '+266',
            name: 'Lesotho'
        },
        {
            flagEmoji: '🇱🇷',
            value: '+231',
            name: 'Liberia'
        },
        {
            flagEmoji: '🇦🇪',
            value: '+218',
            name: 'Libyan Arab Jamahiriya'
        },
        {
            flagEmoji: '🇱🇮',
            value: '+423',
            name: 'Liechtenstein'
        },
        {
            flagEmoji: '🇱🇹',
            value: '+370',
            name: 'Lithuania'
        },
        {
            flagEmoji: '🇱🇺',
            value: '+352',
            name: 'Luxembourg'
        },
        {
            flagEmoji: '🇲🇴',
            value: '+853',
            name: 'Macao'
        },
        {
            flagEmoji: '🇲🇰',
            value: '+389',
            name: 'Macedonia'
        },
        {
            flagEmoji: '🇲🇬',
            value: '+261',
            name: 'Madagascar'
        },
        {
            flagEmoji: '🇲🇼',
            value: '+265',
            name: 'Malawi'
        },
        {
            flagEmoji: '🇲🇾',
            value: '+60',
            name: 'Malaysia'
        },
        {
            flagEmoji: '🇲🇻',
            value: '+960',
            name: 'Maldives'
        },
        {
            flagEmoji: '🇲🇱',
            value: '+223',
            name: 'Mali'
        },
        {
            flagEmoji: '🇲🇹',
            value: '+356',
            name: 'Malta'
        },
        {
            flagEmoji: '🇲🇭',
            value: '+692',
            name: 'Marshall Islands'
        },
        {
            flagEmoji: '🇲🇶',
            value: '+596',
            name: 'Martinique'
        },
        {
            flagEmoji: '🇲🇷',
            value: '+222',
            name: 'Mauritania'
        },
        {
            flagEmoji: '🇲🇺',
            value: '+230',
            name: 'Mauritius'
        },
        {
            flagEmoji: '🇾🇹',
            value: '+262',
            name: 'Mayotte'
        },
        {
            flagEmoji: '🇲🇽',
            value: '+52',
            name: 'Mexico'
        },
        {
            flagEmoji: '🇫🇲',
            value: '+691',
            name: 'Micronesia, Federated States of Micronesia'
        },
        {
            flagEmoji: '🇲🇩',
            value: '+373',
            name: 'Moldova'
        },
        {
            flagEmoji: '🇲🇨',
            value: '+377',
            name: 'Monaco'
        },
        {
            flagEmoji: '🇲🇳',
            value: '+976',
            name: 'Mongolia'
        },
        {
            flagEmoji: '🇲🇪',
            value: '+382',
            name: 'Montenegro'
        },
        {
            flagEmoji: '🇲🇸',
            value: '+1664',
            name: 'Montserrat'
        },
        {
            flagEmoji: '🇲🇦',
            value: '+212',
            name: 'Morocco'
        },
        {
            flagEmoji: '🇲🇿',
            value: '+258',
            name: 'Mozambique'
        },
        {
            flagEmoji: '🇲🇲',
            value: '+95',
            name: 'Myanmar'
        },
        {
            flagEmoji: '🇳🇦',
            value: '+264',
            name: 'Namibia'
        },
        {
            flagEmoji: '🇳🇷',
            value: '+674',
            name: 'Nauru'
        },
        {
            flagEmoji: '🇳🇵',
            value: '+977',
            name: 'Nepal'
        },
        {
            flagEmoji: '🇳🇱',
            value: '+31',
            name: 'Netherlands'
        },
        {
            flagEmoji: '🇧🇶',
            value: '+599',
            name: 'Netherlands Antilles'
        },
        {
            flagEmoji: '🇳🇨',
            value: '+687',
            name: 'New Caledonia'
        },
        {
            flagEmoji: '🇳🇿',
            value: '+64',
            name: 'New Zealand'
        },
        {
            flagEmoji: '🇳🇮',
            value: '+505',
            name: 'Nicaragua'
        },
        {
            flagEmoji: '🇳🇪',
            value: '+227',
            name: 'Niger'
        },
        {
            flagEmoji: '🇳🇬',
            value: '+234',
            name: 'Nigeria'
        },
        {
            flagEmoji: '🇳🇺',
            value: '+683',
            name: 'Niue'
        },
        {
            flagEmoji: '🇳🇫',
            value: '+672',
            name: 'Norfolk Island'
        },
        {
            flagEmoji: '🇲🇵',
            value: '+1670',
            name: 'Northern Mariana Islands'
        },
        {
            flagEmoji: '🇳🇴',
            value: '+47',
            name: 'Norway'
        },
        {
            flagEmoji: '🇴🇲',
            value: '+968',
            name: 'Oman'
        },
        {
            flagEmoji: '🇵🇰',
            value: '+92',
            name: 'Pakistan'
        },
        {
            flagEmoji: '🇵🇼',
            value: '+680',
            name: 'Palau'
        },
        {
            flagEmoji: '🇵🇸',
            value: '+970',
            name: 'Palestinian Territory, Occupied'
        },
        {
            flagEmoji: '🇵🇦',
            value: '+507',
            name: 'Panama'
        },
        {
            flagEmoji: '🇵🇬',
            value: '+675',
            name: 'Papua New Guinea'
        },
        {
            flagEmoji: '🇵🇾',
            value: '+595',
            name: 'Paraguay'
        },
        {
            flagEmoji: '🇵🇪',
            value: '+51',
            name: 'Peru'
        },
        {
            flagEmoji: '🇵🇭',
            value: '+63',
            name: 'Philippines'
        },
        {
            flagEmoji: '🇵🇳',
            value: '+872',
            name: 'Pitcairn'
        },
        {
            flagEmoji: '🇵🇱',
            value: '+48',
            name: 'Poland'
        },
        {
            flagEmoji: '🇵🇹',
            value: '+351',
            name: 'Portugal'
        },
        {
            flagEmoji: '🇵🇷',
            value: '+1939',
            name: 'Puerto Rico'
        },
        {
            flagEmoji: '🇶🇦',
            value: '+974',
            name: 'Qatar'
        },
        {
            flagEmoji: '🇷🇴',
            value: '+40',
            name: 'Romania'
        },
        {
            flagEmoji: '🇷🇺',
            value: '+7',
            name: 'Russia'
        },
        {
            flagEmoji: '🇷🇼',
            value: '+250',
            name: 'Rwanda'
        },
        {
            flagEmoji: '🇷🇪',
            value: '+262',
            name: 'Reunion'
        },
        {
            flagEmoji: '🇧🇱',
            value: '+590',
            name: 'Saint Barthelemy'
        },
        {
            flagEmoji: '🇹🇦',
            value: '+290',
            name: 'Saint Helena, Ascension and Tristan Da Cunha'
        },
        {
            flagEmoji: '🇰🇳',
            value: '+1869',
            name: 'Saint Kitts and Nevis'
        },
        {
            flagEmoji: '🇱🇨',
            value: '+1758',
            name: 'Saint Lucia'
        },
        {
            flagEmoji: '🇲🇫',
            value: '+590',
            name: 'Saint Martin'
        },
        {
            flagEmoji: '🇵🇲',
            value: '+508',
            name: 'Saint Pierre and Miquelon'
        },
        {
            flagEmoji: '🇻🇨',
            value: '+1784',
            name: 'Saint Vincent and the Grenadines'
        },
        {
            flagEmoji: '🇼🇸',
            value: '+685',
            name: 'Samoa'
        },
        {
            flagEmoji: '🇸🇲',
            value: '+378',
            name: 'San Marino'
        },
        {
            flagEmoji: '🇸🇹',
            value: '+239',
            name: 'Sao Tome and Principe'
        },
        {
            flagEmoji: '🇸🇦',
            value: '+966',
            name: 'Saudi Arabia'
        },
        {
            flagEmoji: '🇸🇳',
            value: '+221',
            name: 'Senegal'
        },
        {
            flagEmoji: '🇷🇸',
            value: '+381',
            name: 'Serbia'
        },
        {
            flagEmoji: '🇸🇨',
            value: '+248',
            name: 'Seychelles'
        },
        {
            flagEmoji: '🇸🇱',
            value: '+232',
            name: 'Sierra Leone'
        },
        {
            flagEmoji: '🇸🇬',
            value: '+65',
            name: 'Singapore'
        },
        {
            flagEmoji: '🇸🇰',
            value: '+421',
            name: 'Slovakia'
        },
        {
            flagEmoji: '🇸🇮',
            value: '+386',
            name: 'Slovenia'
        },
        {
            flagEmoji: '🇸🇧',
            value: '+677',
            name: 'Solomon Islands'
        },
        {
            flagEmoji: '🇸🇴',
            value: '+252',
            name: 'Somalia'
        },
        {
            flagEmoji: '🇿🇦',
            value: '+27',
            name: 'South Africa'
        },
        {
            flagEmoji: '🇸🇸',
            value: '+211',
            name: 'South Sudan'
        },
        {
            flagEmoji: '🇬🇸',
            value: '+500',
            name: 'South Georgia and the South Sandwich Islands'
        },
        {
            flagEmoji: '🇪🇸',
            value: '+34',
            name: 'Spain'
        },
        {
            flagEmoji: '🇱🇰',
            value: '+94',
            name: 'Sri Lanka'
        },
        {
            flagEmoji: '🇸🇩',
            value: '+249',
            name: 'Sudan'
        },
        {
            flagEmoji: '🇸🇷',
            value: '+597',
            name: 'Suriname'
        },
        {
            flagEmoji: '🇸🇯',
            value: '+47',
            name: 'Svalbard and Jan Mayen'
        },
        {
            flagEmoji: '🇸🇿',
            value: '+268',
            name: 'Swaziland'
        },
        {
            flagEmoji: '🇸🇪',
            value: '+46',
            name: 'Sweden'
        },
        {
            flagEmoji: '🇨🇭',
            value: '+41',
            name: 'Switzerland'
        },
        {
            flagEmoji: '🇸🇾',
            value: '+963',
            name: 'Syrian Arab Republic'
        },
        {
            flagEmoji: '🇹🇼',
            value: '+886',
            name: 'Taiwan'
        },
        {
            flagEmoji: '🇹🇯',
            value: '+992',
            name: 'Tajikistan'
        },
        {
            flagEmoji: '🇹🇿',
            value: '+255',
            name: 'Tanzania, United Republic of Tanzania'
        },
        {
            flagEmoji: '🇹🇭',
            value: '+66',
            name: 'Thailand'
        },
        {
            flagEmoji: '🇹🇱',
            value: '+670',
            name: 'Timor-Leste'
        },
        {
            flagEmoji: '🇹🇬',
            value: '+228',
            name: 'Togo'
        },
        {
            flagEmoji: '🇹🇰',
            value: '+690',
            name: 'Tokelau'
        },
        {
            flagEmoji: '🇹🇴',
            value: '+676',
            name: 'Tonga'
        },
        {
            flagEmoji: '🇹🇹',
            value: '+1868',
            name: 'Trinidad and Tobago'
        },
        {
            flagEmoji: '🇹🇳',
            value: '+216',
            name: 'Tunisia'
        },
        {
            flagEmoji: '🇹🇷',
            value: '+90',
            name: 'Turkey'
        },
        {
            flagEmoji: '🇹🇲',
            value: '+993',
            name: 'Turkmenistan'
        },
        {
            flagEmoji: '🇹🇨',
            value: '+1649',
            name: 'Turks and Caicos Islands'
        },
        {
            flagEmoji: '🇹🇻',
            value: '+688',
            name: 'Tuvalu'
        },
        {
            flagEmoji: '🇺🇬',
            value: '+256',
            name: 'Uganda'
        },
        {
            flagEmoji: '🇺🇦',
            value: '+380',
            name: 'Ukraine'
        },
        {
            flagEmoji: '🇦🇪',
            value: '+971',
            name: 'United Arab Emirates'
        },
        {
            flagEmoji: '🇬🇧',
            value: '+44',
            name: 'United Kingdom'
        },
        {
            flagEmoji: '🇺🇸',
            value: '+1',
            name: 'United States'
        },
        {
            flagEmoji: '🇺🇾',
            value: '+598',
            name: 'Uruguay'
        },
        {
            flagEmoji: '🇺🇿',
            value: '+998',
            name: 'Uzbekistan'
        },
        {
            flagEmoji: '🇻🇺',
            value: '+678',
            name: 'Vanuatu'
        },
        {
            flagEmoji: '🇻🇪',
            value: '+58',
            name: 'Venezuela, Bolivarian Republic of Venezuela'
        },
        {
            flagEmoji: '🇻🇳',
            value: '+84',
            name: 'Vietnam'
        },
        {
            flagEmoji: '🇻🇬',
            value: '+1284',
            name: 'Virgin Islands, British'
        },
        {
            flagEmoji: '🇻🇮',
            value: '+1340',
            name: 'Virgin Islands, U.S.'
        },
        {
            flagEmoji: '🇼🇫',
            value: '+681',
            name: 'Wallis and Futuna'
        },
        {
            flagEmoji: '🇾🇪',
            value: '+967',
            name: 'Yemen'
        },
        {
            flagEmoji: '🇿🇲',
            value: '+260',
            name: 'Zambia'
        },
        {
            flagEmoji: '🇿🇼',
            value: '+263',
            name: 'Zimbabwe'
        }
    ]
};
