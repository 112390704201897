import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { WebinarService } from 'src/app/dashboard-webinars/services/webinar.service';

@Component({
    selector: 'app-layout-control-wrapper',
    templateUrl: './layout-control-wrapper.component.html',
    styleUrls: ['./layout-control-wrapper.component.scss']
})
export class LayoutControlWrapperComponent implements OnInit, OnChanges {
    @Input() meetingObj;
    @Input() selectedFeedLayoutOption;
    showLayoutPopUp: boolean = false;
    changedLayout: string;
    meetingId;
    selectedLayout: string;
    selectionChanged: boolean = false;
    constructor(
        private webinarService: WebinarService,
        private callStateManagerService: CallStateManagerService,
        private toastrService: ToastrService
    ) {}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedFeedLayoutOption) {
            this.selectedLayout = changes.selectedFeedLayoutOption.currentValue;
            this.changedLayout = this.selectedLayout;
        }
    }

    ngOnInit(): void {
        this.selectedLayout =
            this.selectedLayout ||
            this.meetingObj?.meetingInfo?.webinar?.options?.feedLayout ||
            this.meetingObj.webinarOptions.feedLayout;
        this.changedLayout = this.selectedLayout;
        this.meetingId =
            this.callStateManagerService.meetingObj._id || this.callStateManagerService.meetingObj.meetingId;
    }
    togglePopup(value = null) {
        if (this.showLayoutPopUp) {
            this.selectedLayout = this.changedLayout;
            this.selectionChanged = false;
        }
        this.showLayoutPopUp = !this.showLayoutPopUp;
    }
    discardFormPopUp() {
        this.showLayoutPopUp = false;
        this.selectedLayout = this.changedLayout; // Reset layout when discarding changes
        this.selectionChanged = false;
    }
    selectLayout(layout: string) {
        this.selectedLayout = layout;
        this.selectionChanged = this.selectedLayout !== this.changedLayout;
    }

    confirmLayoutSelection() {
        // Handle the confirmation logic
        if (this.selectedLayout) {
            console.log('Layout selected:', this.selectedLayout);
            this.webinarService.updateFeedLayout(this.meetingId, this.selectedLayout).subscribe(
                (res) => {
                    this.selectionChanged = false;
                    this.changedLayout = this.selectedLayout;
                },
                (error) => {
                    if (error.error.customCode == 400 && error.error.errors == 'Webinar has not yet broadcasted') {
                        this.toastrService.error(error.error.errors);
                    }
                }
            );
        } else {
            console.log('No layout selected');
        }
    }
}
