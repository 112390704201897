import { Injectable, Inject } from '@angular/core';

import { of, Observable, Subject } from 'rxjs';
import { tap, switchMap, map, catchError, shareReplay } from 'rxjs/operators';

import { AppService } from './app.service';
import { RestService } from './rest.service';
import { EventEmitterService } from './event-emitter.service';
import { UtilService } from './util.service';
import { APP_EVENTS } from 'src/app/constants';
import { AppLoggerService } from './app-logger.service';
import { GuestAuthService } from './guest-auth.service';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private user;
    private userRoom;
    private guestUser;
    private isHrPortalFlow = false;
    public guestLogin = false;
    private guestNameRoomOwnerInfo;
    guestUserDetails;
    currentUserName: string;
    userObservable: Observable<any> = null;
    private clearFormSubject = new Subject<void>();

    // Observable for components to listen to form reset event
    clearForm$ = this.clearFormSubject.asObservable();
    constructor(
        private appService: AppService,
        private restService: RestService,
        private eventEmitterService: EventEmitterService,
        private utilService: UtilService,
        private appLoggerService: AppLoggerService,
        private guestUserService: GuestAuthService
    ) {}

    getUserData(userToken) {
        return this.restService
            .get(`${this.appService.getEnvVariable('BASE_URL')}/my_profile`, {
                headers: { Authorization: userToken }
            })
            .pipe(
                tap((user) => {
                    this.user = user;
                }),

                tap((user) => {
                    if (user.isOTT) {
                        user.meetingId = user.jiomeetId;
                    } else {
                        user.isRil = this.appService
                            .getConfigVariable('SAML_DOMAINS')
                            .includes(this.utilService.getDomainFromEmail(user.email));
                    }

                    if (user.phoneNo) {
                        user.displayPhoneNo = user.phoneNo.replace('+91', '');
                    }

                    this.user = user;

                    return user;
                }),

                tap((user) => {
                    this.appLoggerService.setUserInfo(user);
                })
            );
    }

    getUser(force = false) {
        if (this.guestLogin) {
            return of({});
        }
        return Object.keys(this.getUserSync()).length && !force
            ? of(this.getUserSync())
            : this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/my_profile`).pipe(
                  tap((user) => {
                      this.user = user;
                  }),
                  switchMap((user) => {
                      if (!user.jcData) {
                          return this.checkAndGrantJioCloudProvision().pipe(
                              map((data) => {
                                  return Object.assign(this.user, { jcData: data });
                              }),
                              catchError((err) => {
                                  this.appLoggerService.error('Error in Jiocloud API', err);
                                  return of(this.user);
                              })
                          );
                      } else {
                          return of(user);
                      }
                  }),
                  tap((user) => {
                      if (user.webinarBetaAccess?.enablePaymentFeature) {
                          this.appService.setConfigVariable('enablePaymentFeature', true);
                      }
                      if (user.isOTT) {
                          user.meetingId = user.jiomeetId;
                      } else {
                          user.isRil = this.appService
                              .getConfigVariable('SAML_DOMAINS')
                              .includes(this.utilService.getDomainFromEmail(user.email));
                      }

                      if (user.phoneNo) {
                          user.displayPhoneNo = user.phoneNo.replace('+91', '');
                      }

                      this.user = user;
                      this.eventEmitterService.emit({
                          type: APP_EVENTS.UPDATE_PROFILE,
                          data: null
                      });
                      return user;
                  }),
                  shareReplay(1)
              );
    }

    checkAndGrantJioCloudProvision() {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/jc`);
    }

    getUserSync(): any {
        return this.user || this.guestUser || this.guestUserDetails || {};
    }

    getUserNameFromUserInfo() {
        let user = this.getUserSync();
        return `${user?.name || user?.firstName || 'JioEvents User'} ${user?.lname || user?.lastName || ''}`;
    }

    getUserId() {
        return this.getUserSync()?._id || this.guestUserService.getAuthInfo()?.guestUserId;
    }

    getUserRoom() {
        return this.userRoom
            ? of(this.userRoom)
            : this.restService
                  .post(`${this.appService.getEnvVariable('BASE_URL')}/getroom`, {
                      usePersonal: true
                  })
                  .pipe(tap((room) => (this.userRoom = room)));
    }

    getOtherUserRoom(userId): Observable<any> {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/getUserRoom`, {
            userId
        });
    }

    getUserRole(roleId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/roles/${roleId}`);
    }

    getParticipantsCount() {
        if (this.userRoom) {
            return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/getParticipants`, {
                roomID: '' + this.userRoom.roomid
            });
        } else {
            return this.getUserRoom().pipe(switchMap((room) => this.getParticipantsCount()));
        }
    }

    getCallHistory({ offset, limit, keyword }) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/calls?offset=${offset}&limit=${limit}&keyword=${keyword}`
        );
    }

    updateUserProfile({ name, lname }) {
        return this.restService
            .put(`${this.appService.getEnvVariable('BASE_URL')}/my_profile`, {
                name,
                lname
            })
            .pipe(tap(() => (this.user = null)));
    }

    updateUserProfileWithAdditionalProps(payload) {
        return this.restService
            .put(`${this.appService.getEnvVariable('BASE_URL')}/my_profile/update`, payload)
            .pipe(tap(() => (this.user = null)));
    }

    getPersonalRoomPrefernces() {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/user/personalroompreferences`);
    }

    updatePersonalRoomPreferences({ isPinEnabled, roomPIN }) {
        const payload = roomPIN ? { isPinEnabled, roomPIN: roomPIN.toString() } : { isPinEnabled };
        return this.restService
            .put(`${this.appService.getEnvVariable('BASE_URL')}/user/personalroompreferences`, payload)
            .pipe(tap(() => Object.assign(this.user, payload)));
    }

    getMeetingPrefernces() {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/user/meetingpreferences`);
    }

    updateMeetingPrefernces({
        hostAudio,
        participantAudio,
        hostVideo,
        participantVideo,
        waitingRoom,
        participantHardAudioMute,
        colleagueOnly,
        loggedInOnly,
        joinBeforeHost
    }) {
        return this.restService.put(`${this.appService.getEnvVariable('BASE_URL')}/user/meetingpreferences`, {
            hostAudio,
            participantAudio,
            hostVideo,
            participantVideo,
            waitingRoom,
            participantHardAudioMute,
            colleagueOnly,
            loggedInOnly,
            joinBeforeHost
        });
    }

    getProfilePicPath(userId) {
        return `${this.appService.getEnvVariable('BASE_URL')}/profilepic/${userId}/profile.png?random=${Math.random()}`;
    }

    uploadProfilePicture(payload) {
        return this.restService
            .post(`${this.appService.getEnvVariable('BASE_URL')}/my_profile/uploadPic`, payload)
            .pipe(tap(() => (this.user = null)));
    }

    deleteProfilePicture() {
        return this.restService
            .delete(`${this.appService.getEnvVariable('BASE_URL')}/my_profile/unsetPic`)
            .pipe(tap(() => (this.user = null)));
    }

    setOTTPassword(password, hash, name = 'default', lname = 'default') {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/setottpassword`, {
            password,
            confirm_password: password,
            hash,
            name,
            lname
        });
    }

    updateSchoolInfo(props) {
        return this.restService
            .post(`${this.appService.getEnvVariable('BASE_URL')}/my_profile/schooltnc`, props)
            .pipe(tap(() => (this.user = null)));
    }

    updateProfile(props) {
        return this.restService
            .put(`${this.appService.getEnvVariable('BASE_URL')}/my_profile/update`, props)
            .pipe(tap(() => Object.assign(this.user, props)));
    }

    inviteColleagues(emails) {
        return this.restService.put(`${this.appService.getEnvVariable('BASE_URL')}/contacts/invite/email`, emails);
    }

    resetPassword(email, password, hash) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/resetpassword`, {
            email,
            password,
            confirm_password: password,
            hash
        });
    }

    getWaitingRoomList({ jiomeetId }) {
        return this.restService.get(
            `${this.appService.getEnvVariable(
                'BASE_URL'
            )}/shorturl/waitingroom/list?jiomeetId=${jiomeetId}&status=pending`
        );
    }
    acceptWaitingUserWithWaitingRoomId({ waitingRoomId, jiomeetId }) {
        const payload = {
            waitingRoomIds: [waitingRoomId],
            jiomeetId
        };
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/shorturl/waitingroom/accept`,
            payload
        );
    }

    rejectWaitingUserWithWaitingRoomId({ waitingRoomId, jiomeetId }) {
        const payload = {
            waitingRoomIds: [waitingRoomId],
            jiomeetId
        };
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/shorturl/waitingroom/reject`,
            payload
        );
    }

    stopWaitingWithWaitingRoomID({ waitingRoomId, jiomeetId }) {
        const payload = {
            waitingRoomId,
            jiomeetId
        };
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/shorturl/waitingroom/stopwaiting`,
            payload
        );
    }

    acceptWaitingUser({ memberId, jiomeetId }) {
        const payload = {
            memberIds: [memberId],
            jiomeetId
        };
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/shorturl/waitingroom/accept`,
            payload
        );
    }

    rejectWaitingUser({ memberId, jiomeetId }) {
        const payload = {
            memberIds: [memberId],
            jiomeetId
        };
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/shorturl/waitingroom/reject`,
            payload
        );
    }

    stopWaiting({ memberId, jiomeetId }) {
        const payload = {
            memberIds: [memberId],
            jiomeetId
        };
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/shorturl/waitingroom/stopwaiting`,
            payload
        );
    }

    updateHostConnectionStatus(roomID, status: 'connected' | 'disconnected') {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/roomConnectionStatus`, {
            roomID,
            status
        });
    }

    getConferenceDetails(id) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/getconferencedetails?historyId=${id}`
        );
    }

    getRemainingInvitationCont() {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/external/invite`);
    }

    isValidInviteCode(code) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/external/isvalid`, {
            code
        });
    }

    invite(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/external/invite`, payload);
    }

    registerForEnterprise(payload) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/jiomeetenterprise/register`,
            payload
        );
    }

    getFinalTempAuthUrl(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/shorturl/shortlivedurl`, payload);
    }

    setGuestUser(name) {
        this.guestUser = { name, webinarMediaEnginePreference: 'agora' };
    }

    getGuestUser() {
        return this.guestUser || this.guestUserDetails;
    }

    setGuestUserDetails(details) {
        this.guestUserDetails = details;
    }

    getGuestUserDetails() {
        return this.guestUserDetails;
    }

    clearGuestDetails() {
        this.guestUserDetails = null;
        this.clearFormSubject.next();
    }

    setHRPortal(bool) {
        this.isHrPortalFlow = bool;
    }

    isHRPortal() {
        return this.isHrPortalFlow;
    }

    isGuestLogin(bool) {
        this.guestLogin = bool;
    }

    setGuestNameRoomOwnerInfo(info) {
        this.guestNameRoomOwnerInfo = info;
    }

    getGuestNameRoomOwnerInfo() {
        return this.guestNameRoomOwnerInfo;
    }

    cleanup() {
        this.user = null;
        this.userRoom = null;
    }

    setDisplayName(fullName) {
        this.currentUserName = fullName;
    }

    getDisplayName() {
        return this.currentUserName;
    }

    getSubscriptionInfo() {
        return this.user.subscriptionInfo;
    }

    getFeatureAccess(featureFlag, level = 'webinarBetaAccess.') {
        let searchLevel = level;
        if (level == 'root') {
            searchLevel = '';
        }
        return _.get(this.user, `${searchLevel}${featureFlag}`, false);
    }
}
